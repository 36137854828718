import vars from '../helpers';

const $select = $('select') ? $('select') : false;
const datepicker = document.getElementById('datepicker') ? document.getElementById('datepicker') : false;
const tel = document.getElementById('phone') ? document.getElementById('phone') : false;
const passengers = document.getElementById('passengers') ? document.getElementById('passengers') : false;
const boatPage = document.querySelector('.home__section--boat');
const dateForDatepicker = new Date();
let maskPassengers
let maskTel
let maskDate

const initForm = () => {
	if ($select) {
		$('select').selectmenu();
	}

	if (tel) {
		maskTel = new IMask(tel,
			{
				mask: '+{7}(000)000-00-00',
			}
		);
	}
	if (passengers && !boatPage) {
		maskPassengers = new IMask(passengers,
			{
				mask: Number,
				signed: false,
				min: 0,
				max: 75
			}
		);
	}

	if (datepicker) {
		$.datepicker.regional['ru'] = {
			closeText: 'Закрыть',
			prevText: '',
			nextText: '',
			currentText: 'Сегодня',
			monthNames: ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
			monthNamesShort: ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
			dayNames: ['воскресенье','понедельник','вторник','среда','четверг','пятница','суббота'],
			dayNamesShort: ['вск','пнд','втр','срд','чтв','птн','сбт'],
			dayNamesMin: ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'],
			weekHeader: 'Не',
			dateFormat: 'dd.mm.yy',
			firstDay: 1,
			isRTL: false,
			showMonthAfterYear: false,
			yearSuffix: '',
			minDate: dateForDatepicker,
			defaultDate: dateForDatepicker,
		};
		$.datepicker.setDefaults($.datepicker.regional['ru']);

		$('#datepicker').datepicker();
		maskDate = new IMask(datepicker,
			{
				mask: Date,
				pattern: 'd.`m.`y',
				blocks: {
					d: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 31,
						maxLength: 2,
					},
					m: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 12,
						maxLength: 2,
					},
					y: {
						mask: IMask.MaskedRange,
						from: 1900,
						to: 9999,
					}
				},
			}
		);
	}

};

export default {
	initForm,
};
