import vars from '../helpers';

const $boat = $('.boat') ? $('.boat') : false;
const $boatSlider = $('.boat__slider');
const $boatFeatures = $('.boat__features');
const $boatMaps = $('.boat__specification-maps');
const $boatButtonCharacter = $('.boat__specification-button--character');
const $boatButtonRoute = $('.boat__specification-button--route');
const $boatButtonCalendar = $('.boat__specification-button--calendar');
const $boatCharacter = $('.boat__specification-character');
const $boatRoute = $('.boat__specification-route');
const $boatCalendar = $('.boat__specification-calendar');
const $boatButton360 = $('.boat__view--360');
const $boatButton3d = $('.boat__view--3d');
const $boatView360 = $('.boat__view360');
const $boatView3d = $('.boat__view3d');
const $boatClose360 = $('.boat__close360');
const $boatClose3d = $('.boat__close3d');
const $boatButtonBook = $('.button--book');
const $boatPopup = $('.popup');
const $boatPopupClose = $('.popup__close, .popup__overlay');

const initBoat = () => {
	if ($boat) {
		$boatSlider.slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			prevArrow: '<button class="boat__arrow boat__arrow--prev" type="button"><span><svg><use xlink:href="/images/sprites.svg#arrow-prev"></use></svg></span></button>',
			nextArrow: '<button class="boat__arrow boat__arrow--next" type="button"><span><svg><use xlink:href="/images/sprites.svg#arrow-next"></use></svg></span></button>',
		});

		$boatFeatures.slick({
			infinite: false,
			arrows: false,
			mobileFirst: true,
			variableWidth: true,
			swipeToSlide: true,
			edgeFriction: 0,
			responsive: [
				{
					breakpoint: 740,
					settings: {
						slidesToScroll: 3,
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToScroll: 6,
						slidesToShow: 6,
					},
				},
			],
		});

		$boatMaps.slick({
			infinite: false,
			arrows: false,
			mobileFirst: true,
			variableWidth: true,
			swipeToSlide: true,
			edgeFriction: 0,
			responsive: [
				{
					breakpoint: 740,
					settings: {
						slidesToScroll: 2,
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToScroll: 3,
						slidesToShow: 3,
					},
				},
			],
		});

		$boatButtonCharacter.on('click', () => {
			$boatButtonRoute.removeClass('is-active');
			$boatButtonCalendar.removeClass('is-active');
			$boatButtonCharacter.addClass('is-active');
			$boatRoute.slideUp(300);
			$boatCalendar.slideUp(300);
			$boatCharacter.slideDown(300);
		});

		$boatButtonRoute.on('click', () => {
			$boatButtonCharacter.removeClass('is-active');
			$boatButtonCalendar.removeClass('is-active');
			$boatButtonRoute.addClass('is-active');
			$boatCharacter.slideUp(300);
			$boatCalendar.slideUp(300);
			$boatRoute.slideDown(300);
		});

		$boatButtonCalendar.on('click', () => {
			$boatButtonCharacter.removeClass('is-active');
			$boatButtonRoute.removeClass('is-active');
			$boatButtonCalendar.addClass('is-active');
			$boatCharacter.slideUp(300);
			$boatRoute.slideUp(300);
			$boatCalendar.slideDown(300);
		});

		$boatButton360.on('click', () => {
			$boatView360.addClass('is-active');
			$('.cloudimage-360').show();
			$boatSlider.hide();
		});

		$boatButton3d.on('click', () => {
			$boatView3d.addClass('is-active');
			$boatSlider.hide();
		});

		$boatClose360.on('click', () => {
			$boatView360.removeClass('is-active');
			$boatSlider.show();
			$('.cloudimage-360').hide();
		});

		$boatClose3d.on('click', () => {
			$boatView3d.removeClass('is-active');
			$boatSlider.show();
		});
		
		$boatButtonBook.on('click', () => {
			$(vars.html).addClass('no-scroll');
			$boatPopup.removeClass('is-hidden');

			setTimeout(() => {
				$boatPopup.addClass('is-show');
			}, 10);
		});

		$boatPopupClose.on('click', () => {
			$boatPopup.removeClass('is-show');

			setTimeout(() => {
				$boatPopup.addClass('is-hidden');
				$(vars.html).removeClass('no-scroll');
			}, 300);
		});
	}
};

export default {
	initBoat,
};
