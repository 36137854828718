import '@babel/polyfill';
import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/selectmenu';
import IMask from 'imask';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import ruLocale from '@fullcalendar/core/locales/ru';

import 'slick-carousel';

svg4everybody();

window.$ = $;
window.jQuery = $;
window.Calendar = Calendar;
window.timeGridPlugin = timeGridPlugin;
window.ruLocale = ruLocale;
