import './vendor';
import './helpers';
import home from './components/home';
import boat from './components/boat';
import form from './components/form';
import filter from './components/filter';
import service from './components/service';

require('./bootstrap');

const botui = new BotUI('boats-bot');

const userId = window.settings.userId;
const socket = (window['defaultSocket'] = window.io(
    window.settings.socket.url,
    {
        transports: ['websocket'],
        path: window.settings.socket.path
    }));

$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.routes_steps .choose_acc [name="route"]').on('change', function () {
        $('.routes_steps .choose_acc [name="route"]')
            .not(this)
            .prop('checked', false);
    });

    socket.on('connect', () => {
        socket.emit('init', {
            userId: localStorage.getItem('userId')
        });
    });

    socket.on('reconnect', () => {
        socket.emit('init', {
            userId: localStorage.getItem('userId')
        });
    });

    socket.on('accept-order', data => {
        $('.toast-order .mr-auto').text(`Судно ${data.boat.name}`);
        $('.toast-order > .toast-body').text(`Судовладелец готов принять заказ`);
        $('.toast-order').toast('show');
    });

    socket.on('reject-order', data => {
        $('.toast-order .mr-auto').text(`Отказ - Судно ${data.boat.name}`);
        $('.toast-order > .toast-body').html('<p>К сожалению, данный корабль отказался от выполнения заказа. Вы можете продолжить ' +
            'коммуникацию с данным судовладельцем, либо выбрать другой корабль по заданным ранее параметрам.</p>' +
            `<a href="/search?start_date=${data.order.startDate}&start_time=${data.order.startTime}&passengers=${data.order.passengers}&exclude=${data.boat.id}">Найти другой вариант</a><br/>` +
            `<a href="#" class="showChatCl">Продолжить коммуникацию</a>`);
        $('.toast-order').toast('show');

        $('.showChatCl').on('click', () => {
            window['boatChat'].show();
        });
    });

    const chat = () => {
        return {
            show: () => {
                $('.toast-chat').show().toast('show');
            }, hide: () => {
                $('.toast-chat').hide();
            }, message: text => {
                send(text);
            }, register: boatId => {
                socket.on('reconnect', () => {
                    socket.emit('register', {
                        boatId: boatId,
                        userId: userId
                    });
                });

                socket.emit('register', {
                    boatId: boatId,
                    userId: userId
                });

                socket.on(boatId + '-' + userId, newMessage);

                botui.message.add({
                    delay: 200,
                    content: 'Добрый день. Задайте свой вопрос капитану.'
                }).then(() => {
                    return botui.action.text({
                        delay: 200,
                        action: {
                            placeholder: 'Ваш вопрос'
                        }
                    });
                }).then(res => {
                    addInput();
                    send(res.value);
                });
            }
        };
    };

    window['boatChat'] = chat();

    $('.toast-chat').on('hide.bs.toast', () => {
        window['boatChat'].hide();
    });

    $('.openChatWindow').on('click', () => {
        window['boatChat'].show();
    });
});

function send (msg){
    socket.send({text: msg, from: userId});
}

function addInput () {
    botui.action.text({
        action: {
            placeholder: 'Ваш вопрос',
        }
    }).then(res => {
        addInput();
        send(res.value);
    });
}

function newMessage (msg) {
    botui.message.add({
        delay: 200,
        content: msg.from + ': ' + msg.text
    });
}

home.initHome();
boat.initBoat();
form.initForm();
filter.initFilter();
service.initService();
