import vars from '../helpers';

const $catalog = $('.catalog') ? $('.catalog') : false;
const $route = $('.route') ? $('.route') : false;
const $catalogCards = $('.catalog__cards');
const $routeMaps = $('.route__maps');
let resizeTimeout = 0;

const initHome = () => {
	if ($catalog) {
		$catalogCards.slick({
			infinite: false,
			arrows: false,
			mobileFirst: true,
			variableWidth: true,
			responsive: [{
				breakpoint: 1025,
				settings: 'unslick',
			}],
		});

		$(window).on('resize', () => {
			if (resizeTimeout === 0 && !$catalogCards.hasClass('slick-initialized') && !vars.isDesktop()) {
				resizeTimeout = setTimeout(() => {
					resizeTimeout = 0;
					$catalogCards.slick('init');
				}, 500);
			}
		});
	}

	if ($route) {
		$routeMaps.slick({
			infinite: false,
			arrows: false,
			mobileFirst: true,
			variableWidth: true,
			swipeToSlide: true,
			edgeFriction: 0,
			responsive: [
				{
					breakpoint: 740,
					settings: {
						slidesToScroll: 2,
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToScroll: 3,
						slidesToShow: 3,
					},
				},
			],
		});
	}
};

export default {
	initHome,
};
