const $service = $('.service') ? $('.service') : false;
const $serviceSelect = $('.service__select');
const $serviceMapButton = $('.service__map-button');
const $serviceMapRa = $('.service__map-radio input');

const initService = () => {
	if ($service) {
		$serviceSelect.on('click', (e) => {
			$(e.target).closest('.service__line').toggleClass('is-active');
			$(e.target).closest('.service__line').find('.service__content').slideToggle(300);
		});

		$serviceMapButton.on('click', (e) => {
			$serviceMapRa.each((index, check) => {
				check.removeAttribute('checked');
			});
			$(e.target).closest('.service__route').find('.button.is-active').removeClass('is-active');
			$(e.target).closest('.button').addClass('is-active');
			$(e.target).closest('.service__map').find('.service__map-radio input').attr('checked', 'checked');
		});
	}
};

export default {
	initService,
};
