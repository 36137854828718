let vars = {};

vars.html = document.querySelector('html');
vars.body = document.querySelector('body');
vars.header = document.querySelector('header');
vars.isMobile = () => innerWidth <= 739;
vars.isTablet = () => innerWidth >= 740 && innerWidth <= 1024;
vars.isDesktop = () => innerWidth >= 1025;
vars.winWidth = window.innerWidth;

vars.getRandom = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default vars;
