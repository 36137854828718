import vars from '../helpers';

const $search = $('.search') ? $('.search') : false;
const $searchOverlay = $('.search__overlay');
const $searchClose = $('.search__close');
const $searchFilter = $('.search__filter');
const $searchFiltersButton = $('.search__filters-button');

const openFilter = () => {
	$(vars.html).addClass('no-scroll');
	$searchFilter.addClass('is-active');
	$searchOverlay.show(300);
};

const closeFilter = () => {
	$(vars.html).removeClass('no-scroll');
	$searchFilter.removeClass('is-active');
	$searchOverlay.hide(300);
};

const initFilter = () => {
	if ($search) {
		$searchFiltersButton.on('click', () => {
			openFilter();
		});

		$searchOverlay.on('click', () => {
			closeFilter();
		});

		$searchClose.on('click', () => {
			closeFilter();
		});
	}
};

export default {
	initFilter,
};
